import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icSettings from '@iconify/icons-ic/settings';
import icChild from '@iconify/icons-ic/child-care';
import icTime from '@iconify/icons-ic/access-time';
import icChart from '@iconify/icons-ic/outline-bar-chart';
import icFood from '@iconify/icons-ic/outline-fastfood';
import icPerson from '@iconify/icons-ic/baseline-person-outline';
import icPhoto from '@iconify/icons-ic/outline-photo-camera';
import icBrush from '@iconify/icons-ic/outline-brush';
import icFile from '@iconify/icons-ic/outline-attach-file';
import { AuthService } from './services/auth.service';
import { User } from '@shared-lib/models';
import { SplashScreenService } from '@vex/services/splash-screen.service';
import { NavigationSubheading, NavigationLink, NavigationDropdown } from '@vex/interfaces/navigation-item.interface';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SDC Hugo';
  user: User;
  navigationItems:any = [];

  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private authService: AuthService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
          default:
            return null;
        }
      }
    );



    this.authService.user$.subscribe(user => {
      this.user = user;

      this.setNavigation();

      if (this.user?.roles?.admin) {
        this.setAdminMenu();
        this.setSummaryMenu();
      } else if (this.user?.roles?.editor) {
        this.setEditorMenu();
      } else if (this.user?.roles?.access) {
        this.setMyMenu();
      }

      this.navigationService.items = this.navigationItems;

      // this.getOptionsMenu();
      this.splashScreenService.hide();
    });

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     */
    this.configService.updateConfig({
      sidenav: {
        title: 'Hugo Admin',
        imageUrl: 'assets/img/logo-teddy-bgr.svg',
        showCollapsePin: true
      },
      // showConfigButton: false,
      footer: {
        visible: false
      }
    });

  }

  setNavigation() {
    this.navigationItems = [];
    this.setDashboard();
  }

  setDashboard() {
    const dashboard: NavigationLink = {
        type: 'link',
        label: 'Dashboard',
        route: '/',
        icon: icLayers
      };
    this.navigationItems.push(dashboard);
  }

  setSummaryMenu() {
    if (!this.user.roles.admin) return false;

    const summaryMenu: NavigationSubheading = {
      type: 'subheading',
      label: 'Sumár',
      children: [{
        type: 'dropdown',
        label: 'Príjmy a výdaje',
        icon: icChart,
        children: [
          {
            type: 'link',
            label: 'Aktuálny mesiac',
            route: '/summary/month'
          },
          {
            type: 'link',
            label: 'Rok',
            route: '/summary/year'
          },
        ]
      }]
    };
    return this.navigationItems.push(summaryMenu);
  }

  getChildrenAdminMenu(): NavigationDropdown {
    if (!this.user.roles.admin && !this.user.roles.editor) return null;
    const menu = {
      type: 'dropdown',
      label: 'Deti',
      icon: icChild,
      children: [
        {
          type: 'link',
          label: 'Zoznam',
          route: '/children/list'
        },
        {
          type: 'link',
          label: 'Pridať',
          route: '/children/create'
        },
      ]
    } as NavigationDropdown;

    return menu;
  }

  getAttendanceAdminMenu(): NavigationDropdown {
    if (!this.user.roles.admin) return null;

    return {
      type: 'dropdown',
      label: 'Dochádzka',
      icon: icTime,
      children: [
        {
          type: 'link',
          label: 'Mesačný sumár',
          route: '/attendance/month'
        }
      ]
    } as NavigationDropdown;

  }

  getClientsAdminMenu(): NavigationDropdown {
    if (!this.user.roles.admin) return null;

    return {
      type: 'dropdown',
      label: 'Užívatelia',
      icon: icPerson,
      children: [
        {
          type: 'link',
          label: 'Zoznam',
          route: '/users'
        }
      ]
    } as NavigationDropdown;
  }

  getMealsAdminMenu(): NavigationDropdown {
    if (!this.user.roles.admin && !this.user.roles.editor) return null;

    return {
      type: 'dropdown',
      label: 'Jedálny lístok',
      icon: icFood,
      children: [
        {
          type: 'link',
          label: 'Aktuálny týždeň',
          route: '/meals'
        }
      ]
    } as NavigationDropdown;
  }

  getActivitiesAdminMenu(): NavigationDropdown {
    if (!this.user.roles.admin && !this.user.roles.editor) return null;

    return {
      type: 'dropdown',
      label: 'Aktivity',
      icon: icBrush,
      children: [
        {
          type: 'link',
          label: 'Aktuálny týždeň',
          route: '/activities'
        }
      ]
    } as NavigationDropdown;
  }

  getDocumentsAdminMenu(): NavigationLink {
    if (!this.user.roles.admin) return null;

    const optionsMenu: NavigationLink = {
      type: 'link',
      label: 'Dokumenty',
      icon: icFile,
      route: '/documents/all'
    }

    return optionsMenu;
  }

  setAdminMenu() {
    const myMenu: NavigationSubheading = {
      type: 'subheading',
      label: 'Administrácia',
      children: [
        this.getChildrenAdminMenu(),
        this.getAttendanceAdminMenu(),
        this.getMealsAdminMenu(),
        this.getActivitiesAdminMenu(),
        this.getGalleriesMenu(),
        this.getDocumentsAdminMenu(),
        this.getClientsAdminMenu(),
      ]
    };

    this.navigationItems.push(myMenu);
  }

  setEditorMenu() {
    const myMenu: NavigationSubheading = {
      type: 'subheading',
      label: 'Administrácia',
      children: [
        this.getMealsAdminMenu(),
        this.getActivitiesAdminMenu(),
        this.getGalleriesMenu(),
      ]
    };

    this.navigationItems.push(myMenu);
  }

  setMyMenu() {
    const myMenu: NavigationSubheading = {
      type: 'subheading',
      label: 'Moje',
      children: [
        this.getChildreMenu(),
        this.getAttendanceMenu(),
        this.getMealsMenu(),
        this.getActivitiesMenu(),
        this.getGalleriesMenu(),
        this.getDocumentsMenu(),
      ]
    };

    this.navigationItems.push(myMenu);
  }

  getDocumentsMenu(): NavigationDropdown {
    if (!this.user.roles.documents) return null;

    const documentsMenu: NavigationDropdown = {
      type: 'dropdown',
      label: 'Dokumenty',
      icon: icFile,
      children: [{
        type: 'link',
        label: 'Moje dokumenty',
        route: `/documents/personal`,
      }, {
        type: 'link',
        label: 'Všeobecné dokumenty',
        route: `/documents/general`,
      }]
    }

    return documentsMenu;
  }

  getChildreMenu(): NavigationDropdown {
    let submenu: NavigationDropdown = null;

    const myChildren = this.user.assignedChildren;
    if (myChildren)
      submenu = {
        type: 'dropdown',
        label: 'Profil',
        icon: icChild,
        children: myChildren.map(child => {
          return {
            type: 'link',
            label: child.displayName,
            route: `/children/view/${child.id}`,
          } as NavigationLink
        })
      };

    return submenu;
  }


  getAttendanceMenu(): NavigationDropdown {
    if (!this.user.roles.attendance) return null;
    const myChildren = this.user.assignedChildren;

    let submenu = null;
    if (myChildren)
      submenu = {
        type: 'dropdown',
        label: 'Dochádzka',
        icon: icTime,
        children: myChildren.map(child => {
            return {
              type: 'link',
              label: `${child.displayName}`,
              route: `/attendance/view/${child.id}`,
            } as NavigationLink
          }
        )
      };
    return submenu;
  }

  getMealsMenu(): NavigationLink {
    if (!this.user.roles.meals) return null;

    const optionsMenu: NavigationLink = {
      type: 'link',
      label: 'Jedálny lístok',
      icon: icFood,
      route: '/meals'
    }
    return optionsMenu;
  }

  getActivitiesMenu(): NavigationLink {
    if (!this.user.roles.activities) return null;

    const optionsMenu: NavigationLink = {
      type: 'link',
      label: 'Aktivity',
      icon: icBrush,
      route: '/activities'
    }
    return optionsMenu;
  }

  getOptionsMenu() {
    const optionsMenu: NavigationSubheading = {
      type: 'subheading',
      label: 'Options',
      children: [
        {
          type: 'link',
          label: 'Settings',
          icon: icSettings,
          route: '/settings'
        },
      ],
    }
    this.navigationItems.push(optionsMenu);
  }

  getGalleriesMenu(): NavigationLink {
    if (!this.user.roles.galleries && !this.user.roles.admin && !this.user.roles.editor) return null;
    const optionsMenu: NavigationLink = {
      type: 'link',
      label: 'Galérie',
      icon: icPhoto,
      route: '/galleries'
    }

    return optionsMenu;
  }

}
