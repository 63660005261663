import { DbEntry } from './db-entry';

export enum ShareType {
    private = 'privatefile',
    shared = 'publicfile',
}

export enum AccessType {
    public = 'publicAccess',
    private = 'restrictedAccess'
}

export interface Document extends DbEntry {
    date: Date;
    name: string;
    path: string;
    extension: string;
    size: number;
    description?: string;
    slug: string;
    tags: string[];
    isPublic: boolean;
    sharedWithAll: boolean;
    sharedTo: string[];
    downloads: number;
    version: number;
}
