import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
  file: File,
  url: SafeUrl
}

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
  element;

  constructor(
    private sanitizer: DomSanitizer,
    private eleRef: ElementRef
  ) {
    this.element = this.eleRef.nativeElement;
  }

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    const files: FileHandle[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push({ file, url });
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }

}
