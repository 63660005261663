<div
  (mouseenter)="collapseOpenSidenav()"
  (mouseleave)="collapseCloseSidenav()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col"
>
  <div class="sidenav-toolbar flex-none flex items-center">
    <img [src]="imageUrl$ | async" class="w-8 select-none flex-none" />
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">
      {{ title$ | async }}
    </h2>
    <button
      (click)="toggleCollapse()"
      *ngIf="showCollapsePin$ | async"
      class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
      mat-icon-button
      type="button"
    >
      <mat-icon
        *ngIf="!collapsed"
        class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
      >radio_button_checked</mat-icon>
      <mat-icon
        *ngIf="collapsed"
        class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
      >radio_button_unchecked</mat-icon>
    </button>
  </div>

  <div class="sidenav-items flex-auto" vexScrollbar>
    <vex-sidenav-item
      *ngFor="let item of items; trackBy: trackByRoute"
      [item]="item"
      [level]="0"
    ></vex-sidenav-item>
  </div>

  <div *ngIf="authService.user$ | async as user" class="flex flex-col">
    <div class="sidenav-items flex-none">
      <a
        [routerLink]="['/profile']"
        class="flex flex-row px-6 pb-2 border-b items-center"
      >
        <img
          [src]="user.photoURL"
          class="avatar h-6 w-6 align-middle mr-2"
          [class.mr-2]="!collapsed || (collapsedOpen$ | async)"
        />
        <div *ngIf="!collapsed || (collapsedOpen$ | async)" class="flex-auto">
          {{ user.displayName }}<br />
          <small>{{ user.email }}</small>
        </div>
      </a>
      <vex-sidenav-item
        [item]="logoutItem"
        [level]="0"
        (click)="authService.signOut()"
      ></vex-sidenav-item>
    </div>
  </div>
</div>
