export interface Roles {
    activities?: boolean;
    documents?: boolean;
    videos?: boolean;
    admin?: boolean;
    galleries?: boolean;
    attendance?: boolean;
    child?: boolean;
    meals?: boolean;
    access?: boolean;
    editor?: boolean;
}
