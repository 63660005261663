
import { Total, Month } from '../models/month';
import { AttendanceService } from '../models/attendance-service';
import { Day } from '../models/day';


export class TotalService {

    /**
     *
     * @param month calculate total price
     * @param service
     */
    public static calculateTotal(month: Month, service: AttendanceService): Total {
        const total = {
            servicePrice: TotalService.getPrice(month, service),
            price: TotalService.getTotalPrice(month, service),
            lunchPrice: TotalService.getLunchPrice(month, service),
            lunchCount: TotalService.getLunchCount(month),
            days: month.days?.length || 0,
            time: TotalService.getTotalHours(month.days),
        } as Total;

        return total;
    }

    /**
     * get number of lunches
     * @param month
     */
    static getLunchCount(month: Month): number {
        if (!month.days) return 0;
        return month.days.reduce((total: number, item: any) => (item.lunch) ? total + 1 : total, 0);
    }

    /**
     * get price for lunch
     * @param month
     * @param service
     */
    static getLunchPrice(month: Month, service: AttendanceService): number {
        const pricePerLunch = service.lunchPrice || 0;
        const price = TotalService.getLunchCount(month) * pricePerLunch;
        return Math.round(price * 100) / 100;
    }

    /**
     *
     * @param month get price
     * @param service
     */
    static getPrice(month: Month, service: AttendanceService): number {
        let total = 0;
        const diff = service.hours - TotalService.getTotalHours(month.days);
        total = (diff > 0) ? service.monthlyFee : (+service.monthlyFee + (Math.abs(diff) * service.additionalHourFee))
        return Math.round(total * 100) / 100;
    }

    /**
     *
     * @param month calculate total price
     * @param service
     */
    static getTotalPrice(month: Month, service: AttendanceService): number {
        let total = 0;
        const diff = service.hours - TotalService.getTotalHours(month.days);
        total = (diff > 0) ? +service.monthlyFee : (+service.monthlyFee + (Math.abs(diff) * service.additionalHourFee))
        total = (service.lunchIncluded === true) ? total : (total + TotalService.getLunchPrice(month, service));
        return Math.round(total * 100) / 100;
    }

    /**
     * calculate total hours
     * @param days
     */
    static getTotalHours(days: Day[]): number {
        let hours = 0;
        if (!days) return hours;
        days.forEach((item: any) => {
            hours += (item.to.seconds - item.from.seconds) / 3600;
        });
        return (Math.round(hours * 100) / 100) || 0;
    }
}