import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'size' })
export class SizePipe implements PipeTransform {

    transform(value: number|null): string {
        if (!value && isNaN(value)) return '';
        let size = value / 1024;
        if (value < 1024 ) {
            return `${Math.round(size)} KB`;
        }

        size = size / 1024;
        return `${Math.round(size*100) / 100} MB`;

    }
}