import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';

import { environment } from '../environments/environment';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { VexModule } from '../@vex/vex.module';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';

import { SharedModule } from './shared.module';

import { RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { SortByPipe } from './pipes/sort-by.pipe';

import { MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from './services/custom-date.adapter';

import { registerLocaleData } from '@angular/common';
import localeSk from '@angular/common/locales/sk';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AdminGuard } from './services/admin.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NewUserGuard } from './services/new-user.guard';
import { AccessGuard } from './services/access.guard';
import { EditorGuard } from './services/editor.guard';


registerLocaleData(localeSk);
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD. MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    SortByPipe,
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    CustomLayoutModule,
    SharedModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatSnackBarModule
  ],
  exports: [
    SharedModule,
    RouterModule,
    VexModule,
    CustomLayoutModule,
    SortByPipe,
  ],
  providers: [
    AuthGuard,
    AccessGuard,
    AdminGuard,
    NewUserGuard,
    EditorGuard,
    { provide: LOCALE_ID, useValue: 'sk-sk' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: MAT_DATE_LOCALE, useValue: 'sk-SK' },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: REGION, useValue: 'europe-west3' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
