import { DbEntry } from './db-entry';

export interface Activity extends DbEntry{
    date: Date,
    monday: ActivityItem,
    tuesday: ActivityItem,
    wednesday: ActivityItem,
    thursday: ActivityItem,
    friday: ActivityItem,
    notes?: string,
}

export interface ActivityItem {
    date: Date,
    title?: string;
    goal?: string;
    resource?: string;
    task?: string;
    evaluation?: string;
}