import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { ErrorService } from '@app/services/error.service';

@Injectable()
export class EditorGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private errorService: ErrorService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map(user => !!user && this.auth.canEdit(user)), // <-- map to boolean
      tap(isEditor => {
        if (!isEditor) {
          this.errorService.errorMgmt(new Error('Access denied'), 'canactivate');
          this.router.navigate(['/']).catch();
        }
      })
    )
  }
}