
export interface Summary {
    id: string,
    year: number,
    month: number,
    total: number,
    totalPaid: number,
    care: number,
    carePaid: number,
    salary: number,
    salaryPaid: number,
    income: number,
    incomePaid: number,
    outcome: number,
    outcomePaid: number,
    items: SummaryItem[],
}

export enum SummaryItemCategories {
    care = 'Opatrovanie',
    salary = 'Výplata',
    outcome = 'Iný výdaj',
    income = 'Iný príjem'
}


export interface SummaryItem {
    id?: string,
    label: string,
    category: SummaryItemCategories|string,
    isIncome: boolean,
    amount: number,
    isPaid: boolean,
}