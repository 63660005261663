import { DbEntry } from './db-entry';
import { AttendanceService } from './attendance-service';
import { Day } from './day';
import { Child } from './child';
import { Invoice } from './invoice';

export interface Total {
    price: number;
    lunchPrice: number;
    servicePrice: number;
    lunchCount: number;
    days: number;
    time: number;
}

export interface Month extends DbEntry {
    facture: string;
    id: string;
    month: number;
    year: number;
    date: Date;
    total: Total;
    service: AttendanceService;
    days: Day[];
    note: string;
    child: Child;
    invoice: Invoice;
}