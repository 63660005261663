<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [icIcon]="icPerson"></mat-icon>
      </div>
      <div class="dropdown-heading" *ngIf="authService.user$ | async as user">{{ user.displayName }}</div>
    </div>

  </div>

  <div class="dropdown-content">
    <a (click)="close()"
       *ngFor="let item of items; trackBy: trackById"
       [routerLink]="item.route"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon [icIcon]="item.icon"
                [ngClass]="item.colorClass"
                class="notification-icon"
                fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ item.label }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <a (click)="logout()" color="primary" mat-button>LOGOUT</a>
  </div>
</div>


