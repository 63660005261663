import { UserStatus } from '../constants';
import { Child, Roles } from '.';


export interface User {
    notifications: boolean;
    uid: string;
    firstName?: any;
    lastName?: any;
    email: string;
    photoURL?: string;
    displayName?: string;
    roles?: Roles;
    assignedChildren?: Child[];
    createdAt?: Date;
    modifiedAt?: Date;
    address?: string;
    phone?: string;
    status?: UserStatus;
    notes?: string;
    relation?: string;
    someblabla: string;
}
