import { DbEntry } from './db-entry';
import { AttendanceService } from './attendance-service';
import { User } from './user';

export enum AttendanceType {
    regular = 'Stály',
    occasional = 'Občasný'
}

export enum Gender {
    boy = 'Chlapec',
    girl = 'dievča'
}

export interface Child extends DbEntry {
    id: string;
    firstName: string;
    lastName: string;
    birthDate: Date;
    gender: Gender;
    startActivity: Date;
    endActivity?: Date;
    isActive: boolean;
    image: string;
    attendanceType: AttendanceType;
    service: AttendanceService;
    notes?: string;
    avatar: string;
    displayName: string;
    assignedUsers: User[];
}

export interface Person {
    id: string;
    firstName: string;
    lastName: string;
    address?: string;
    phone?: string;
    displayName: string;
    relation?: string;
    notes?: string;
}
