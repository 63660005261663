import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import theme from '../../@vex/utils/tailwindcss';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/services/config.service';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';

@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
})
export class CustomLayoutComponent implements OnInit, OnDestroy {


  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.breakpointObserver.observe(`(min-width: ${theme.screens.lg})`).pipe(
    map(state => state.matches)
  );

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(false),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private breakpointObserver: BreakpointObserver,
              private router: Router) { }

  ngOnInit() {
    // this.layoutService.configpanelOpen$.pipe(
    //   untilDestroyed(this)
    // ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }

  ngOnDestroy(): void {}
}
