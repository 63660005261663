import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {


    // deserialize(value: any): Moment | null {
    //     console.log('deserialize');
    //     console.log(value);
    //     // const date = moment(value, environment.APP_DATE_FORMAT);
    //     return moment();
    // }
    // parse(value: any, parseFormat: string | string[] ): Moment {
    //     console.log('value');
    //     console.log(value);
    //     // const date = moment(value, environment.APP_DATE_FORMAT);
    //     return moment();
    // }

}