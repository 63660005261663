import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { take } from 'rxjs/operators';
import { environment } from '@app/../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  trace: any;

  errors$ = new Subject<any>();

  constructor(
    private snackBar: MatSnackBar,
    public db: AngularFirestore,
    private auth: AuthService,
  ) {

  }

  error(e: any) {

  }

  async log(type: string, message: string, detail: string = '') {
    const date = new Date();
    const user = await this.auth.user$.pipe(take(1)).toPromise();
    const userId = user?.uid ?? '0';
    const log = {
      type,
      message,
      detail,
      date,
      user: userId,
      version: environment.version,
      url: window.location.toString(),
    };

    this.db.collection('log').doc(date.toISOString()).set(log).catch(e => console.error(e));
  }

  errorMgmt(err: Error, name: string, show: boolean = true): Observable<any>{
    this.errors$.next(err);

    if (show) {
      this.snackBar.open('Error', err.message, {
        duration: 10000
      });
    }

    // this.trace.putAttribute('error_message', err.message);
    // this.trace.putAttribute('error_name', name);

    this.log(name, err.message, err.stack).catch();
    return of(null);
  }
}
