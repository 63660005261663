export const environment = {
  production: true,
  version: '0.5.0',
  APP_DATE_FORMAT: 'DD/MM/YYYY',
  firebaseConfig: {
    apiKey: 'AIzaSyD5-YWWqcx1kzNZCJye_J5BOkG8tIHFfYs',
    authDomain: 'sdc-hugo-admin.firebaseapp.com',
    databaseURL: 'https://sdc-hugo-admin.firebaseio.com',
    projectId: 'sdc-hugo-admin',
    storageBucket: 'sdc-hugo',
    messagingSenderId: '716890993804',
    appId: '1:716890993804:web:44bc4259bc7da8bddef7a9',
    measurementId: 'G-ST958HGRJB'
  },
};
