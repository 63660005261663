import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { AuthGuard } from './services/auth.guard';
import { AdminGuard } from './services/admin.guard';
import { AccessGuard } from './services/access.guard';
import { NewUserGuard } from './services/new-user.guard';

const childrenRoutes: VexRoutes = [
  {
    path: 'children',
    loadChildren: () => import('./child/child.module').then(m => m.ChildModule),
  },
  {
    path: 'attendance',
    loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'activities',
    loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
  },
  {
    path: 'meals',
    loadChildren: () => import('./meal/meal.module').then(m => m.MealModule),
  },
  {
    path: 'galleries',
    loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule),
  },
  {
    path: 'documents',
    loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./user/user-profile/user-profile.module').then(m => m.UserProfileModule),
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: '*',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  }
];


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'jedalny-listok',
    loadChildren: () => import('./meal/week-page/week-page.module').then(m => m.WeekPageModule),
  },
  {
    path: 'aktivity',
    loadChildren: () => import('./activity/week-page/week-page.module').then(m => m.WeekPageModule),
  },
  {
    path: 'photos',
    loadChildren: () => import('./gallery/gallery-page/gallery-page.module').then(m => m.GalleryPageModule),
  },
  {
    path: 'additional-info',
    loadChildren: () => import('./pages/login-info/login-info.module').then(m => m.LoginInfoModule),
    canActivate: [AuthGuard, NewUserGuard],
  },
  {
    path: 'file',
    loadChildren: () => import('./document/download/download.module').then(m => m.DownloadModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: childrenRoutes,
    canActivate: [AuthGuard, AccessGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}