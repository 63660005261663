import { DbEntry } from './db-entry';

export interface Meal extends DbEntry{
    date: Date,
    monday: MealItem,
    tuesday: MealItem,
    wednesday: MealItem,
    thursday: MealItem,
    friday: MealItem,
    notes?: string,
}

export interface MealItem {
    date: Date,
    breakfast?: string,
    morningSnack?: string,
    lunch?: string,
    afternoonSnack?: string,
    notes?: string,
}