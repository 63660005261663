import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
// import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
// import icListAlt from '@iconify/icons-ic/twotone-list-alt';
// import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { PopoverRef } from '../../popover/popover-ref';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: icAccountCircle,
      label: 'Môj profil',
      description: 'Personal Information',
      colorClass: 'text-teal-500',
      route: '/profile'
    },
  ];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;

  constructor(
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    public authService: AuthService
    ) {

  }

  ngOnInit() {
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.popoverRef.close();
    this.authService.signOut();
  }
}
