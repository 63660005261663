import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { ErrorService } from '@app/services/error.service';
import { UserStatus } from '@shared-lib';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private errorService: ErrorService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map(user => {
        if (user?.status && user.status === UserStatus.new) {
          this.router.navigate(['/additional-info']).catch();
        }
        return !!user && !!user.roles && (user.roles.access || user.roles.admin)
      }),
      tap(hasAccess => {
        if (!hasAccess) {
          this.errorService.errorMgmt(new Error('Nemáte oprávnenia'), 'accessguard');
          this.router.navigate(['/login']).catch();
        }
      })
    )
  }
}