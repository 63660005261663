<div class="py-3" fxLayout="row" fxLayoutAlign="start center">
    <div
        class="w-10 h-10 rounded-full bg-primary-50 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
        <ic-icon [icon]="icon" size="20px"></ic-icon>
    </div>

    <div>
        <p class="m-0 caption text-hint">{{label}}</p>
        <div class="m-0 body-1">
            <ng-content></ng-content>
        </div>
    </div>
</div>