import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IconModule } from '@visurel/iconify-angular';

import { RouterModule } from '@angular/router';

import { BadgeComponent } from './shared/badge/badge.component';
import { EditorModule } from './shared/editor/editor.module';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { HoursPipe } from './pipes/hours.pipe';
import { InfoComponent } from './shared/info/info.component';
import { SizePipe } from './pipes/size.pipe';

@NgModule({
  declarations: [
    BadgeComponent,
    DragAndDropDirective,
    HoursPipe,
    SizePipe,
    InfoComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    IconModule,
    MatSlideToggleModule,
    EditorModule,
  ],
  exports: [
    BadgeComponent,
    EditorModule,
    DragAndDropDirective,
    HoursPipe,
    SizePipe,
    InfoComponent,
  ],
  providers: [
  ]
})

export class SharedModule { }
