import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@shared-lib'; // optional

import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Roles } from '@shared-lib/models/roles';

@Injectable({ providedIn: 'root' })
export class AuthService {

  user$: Observable<User>;
  _loggined = false;
  isAdmin: Observable<boolean>;
  isEditor: Observable<boolean>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {

    // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          this._loggined = true;
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      })
    )

    this.isAdmin = this.hasRole(['admin']);
    this.isEditor = this.hasRole(['admin', 'editor']);

  }

  isLoggedin() {
    return this._loggined;
  }

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async facebookSignin() {
    const provider = new auth.FacebookAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async emailSignin(email: string, password: string) {
    // const provider = new auth.EmailAuthProvider();
    const credential = await this.afAuth.signInWithEmailAndPassword(email, password);
    // const credential = await this.afAuth.createUserWithEmailAndPassword(email, password);
    return this.updateUserData(credential.user);
  }

  async register(email: string, password: string) {
    // const provider = new auth.EmailAuthProvider();
    try {
      const credential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      return this.updateUserData(credential.user);
    } catch (e) {
      throw new Error('Zadaný email je už zaregistrovaný');
    }
  }

  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    } as User;

    return userRef.set(data, { merge: true })

  }

  async signOut() {
    await this.afAuth.signOut();
    this.router.navigate(['/login']).catch();
  }

  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) return true;
    }
    return false;
  }

  roles(): Observable<Roles> {
    return this.user$.pipe(
      map(user => user.roles)
    )
  }

  hasRole(roles: string[]): Observable<boolean> {
    return this.user$.pipe(
      map(user => user.roles && !!roles.find(role => user.roles[role] === true))
    )
  }

  canRead(user: User): boolean {
    const allowed = ['admin', 'editor', 'subscriber'];
    return this.checkAuthorization(user, allowed);
  }
  canEdit(user: User): boolean {
    const allowed = ['admin', 'editor'];
    return this.checkAuthorization(user, allowed);
  }
  canDelete(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

}