import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hours' })
export class HoursPipe implements PipeTransform {

    transform(value: any): string {
        if (!value && isNaN(value)) return '';

        const suffix = 'h';
        const min = Math.round((value%1) * 60);
        const hours = Math.floor(value);

        const mstr = (min < 10) ? `0${min}` : +min;
        const minString = (min === 0) ? '' : mstr;
        return `${hours}${suffix}${minString}`;
    }
}