import { Image } from './image';

export interface Gallery {
    id: string,
    name: string,
    date: Date,
    isPublic: boolean,
    isPublished: boolean,
    coverPhoto: Image,
    photos: Image[],
}
