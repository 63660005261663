import { Component, forwardRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
  selector: 'rte-editor',
  templateUrl: './editor.component.html',
  styleUrls: [
    '../../../../../../node_modules/quill/dist/quill.snow.css',
    '../../../@vex/styles/partials/plugins/_quill.scss',
    './editor.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorComponent),
    multi: true,
  }]
})
export class EditorComponent implements ControlValueAccessor, OnDestroy {
  newValue = '';
  value = '';
  isDisabled = false;
  form: UntypedFormControl;


  onTouched: () => { };
  onChange: (value: string) => void = () => { };

  propagateChange = (_: any) => { };

  writeValue(value: string): void {
    this.form.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  constructor() {
    this.isDisabled = false;
    this.form = new UntypedFormControl('');
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => this.onChange(value));
  }

  ngOnDestroy(): void {
  }

}
